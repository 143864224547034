
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CONTRAT0_SERVICO = "setListTipoProtocolo";
export const SET_LISTA_CONTRAT0_SERVICO_REV = "setListTipoProtocolosRev";
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CONTRAT0_SERVICO = "setConta"


const actions = {
    //protocolos
    async create_contrato_servico(context, value) {

        await ApiService.post('contrato_servicos', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_contrato_servico(context, value) {

        await ApiService.put('contrato_servicos/' + value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_contrato_servico(context) {
        await ApiService.get('contrato_servicos')
            .then(response => {
                context.commit(SET_LISTA_CONTRAT0_SERVICO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },




    set_contrato_servico(context, item) {
        context.commit(SET_CONTRAT0_SERVICO, item)
    },

    async delete_contrato_servico(context, value) {


        await ApiService.delete("contrato_servicos/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );


        context.commit(SET_LISTA_CONTRAT0_SERVICO_REV, value)


    },

 



};
const getters = {};

const mutations = {

    [SET_LISTA_CONTRAT0_SERVICO](state, value) {
        state.lista_contrato_servico = value;
    },
    [SET_LISTA_CONTRAT0_SERVICO_REV](state, value) {
        state.lista_contrato_servico = state.lista_contrato_servico.filter(
            (contrato_servicos) => contrato_servicos.id !== value.id
        );
    },
    [SET_CONTRAT0_SERVICO](state, value) {
        state.contrato_servicos = value;
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },




};

const state = {
    lista_contrato_servico: [],
    contrato_servicos: {},
    mensagem_alert: "",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
