import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_USUARIOS = "setListUsuariosMatriculas"
export const SET_LISTA_USUARIOS_PESQUISA = "setListUsuariosPesquisa"
export const SET_LISTA_USUARIO = "setListMatriculasUsuarios"
export const SET_MESSAGE_ALERT = 'setMensagem'

export const SET_CEP = 'setCep'
const actions = {
    async create_usuario(context, value) {
        let message = await ApiService.post('usuario/criar', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_usuario(context, value) {
        let message = await ApiService.put('usuario/atualizar/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async listar_usuarios(context, params) {
        await ApiService.get('usuario', {params: params})
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    async listar_funcionario(context) {
        await ApiService.get('usuario/filial/1' )
            .then(response => context.commit(SET_LISTA_USUARIOS, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

  

  
    async pesquisar_usuarios(context, texto, tipo) {
        await ApiService.get('usuario/', texto, '/', tipo)
            .then(response => context.commit(SET_LISTA_USUARIOS_PESQUISA, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    
    async listar_usuario(context, id) {
        await ApiService.get('usuario/' + id)
            .then(response =>
                context.commit(SET_LISTA_USUARIO, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },

    
    async troca_senha(context, value) {
        let message = await ApiService.put('usuario/trocar/senha/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },

    async set_cep(context, value) {
        await ApiService.get('usuario/cep/' + value)
            .then(response =>
                context.commit(SET_CEP, response.data))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error', message: error.response.data
            }))
    },
    async recuperar_senha(context, value) {
        let message = await ApiService.post('recuperar-senha',value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    
};

const getters = {};

const mutations = {
    [SET_LISTA_USUARIOS](state, value) {
        state.lista_usuarios = value
    },
    [SET_LISTA_USUARIOS_PESQUISA](state, value) {
        state.lista_usuarios = value
    },
    [SET_LISTA_USUARIO](state, value) {
        state.lista_usuario = value
    },
    [SET_CEP](state, value) {
        state.cep = value
    },
    
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
};

const state = {
    lista_usuarios: [],
    lista_usuario: [],
    mensagem_alert: "",
    cep:{}

};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};