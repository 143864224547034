import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                //#region configs
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue")
                },

                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () => import("@/view/components/rh/createFuncionario.vue")
                },
                {
                    path: "/dadosUsuario",
                    name: "dadosUsuario",
                    component: () => import("@/view/components/rh/dadosUsuario.vue")
                },

                {
                    path: "/gerenFuncionarios",
                    name: "gerenFuncionarios",
                    component: () => import("@/view/components/rh/gerenFuncionario.vue")
                },
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },

                //#endregion

                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO


                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },


                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///



                {
                    path: "/setor",
                    name: "setor",
                    component: () =>
                        import("@/view/components/config/setor/listaSetor.vue")
                },

                {
                    path: "/createSetor",
                    name: "createSetor",
                    component: () =>
                        import("@/view/components/config/setor/createSetor.vue")
                },
                {
                    path: "/contratoServico",
                    name: "contratoServico",
                    component: () =>
                        import("@/view/components/financeiro/contratoServico/listaContratoServico.vue")
                },

                {
                    path: "/createContratoServico",
                    name: "createContratoServico",
                    component: () =>
                        import("@/view/components/financeiro/contratoServico/createContratoServico.vue")
                },


                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import("@/view/components/config/perfil/listaPerfil.vue")
                },

                {
                    path: "/createPerfil",
                    name: "createPerfil",
                    component: () =>
                        import("@/view/components/config/perfil/createPerfil.vue")
                },

                //#endregion

                //#region finan

                {
                    path: "/tipoProduto",
                    name: "tipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/listaTipoProduto.vue")
                },

                {
                    path: "/createTipoProduto",
                    name: "createTipoProduto",
                    component: () =>
                        import("@/view/components/financeiro/tipoProduto/createTipoProduto.vue")
                },
                {
                    path: "/produto",
                    name: "produto",
                    component: () =>
                        import("@/view/components/financeiro/produto/listaProduto.vue")
                },

                {
                    path: "/createProduto",
                    name: "createProduto",
                    component: () =>
                        import("@/view/components/financeiro/produto/createProduto.vue")
                },

                {
                    path: "/createModoPagamento",
                    name: "createModoPagamento",
                    component: () =>
                        import("@/view/components/financeiro/pagamento/createModoPagamento.vue")
                },
                {
                    path: "/modoPagamento",
                    name: "modoPagamento",
                    component: () =>
                        import("@/view/components/financeiro/pagamento/listaModoPagamento.vue")
                },
                {
                    path: "/createFormaPagamento",
                    name: "createFormaPagamento",
                    component: () =>
                        import("@/view/components/financeiro/pagamento/createFormaPagamento.vue")
                },
                {
                    path: "/formaPagamento",
                    name: "formaPagamento",
                    component: () =>
                        import("@/view/components/financeiro/pagamento/listaFormaPagamento.vue")
                },


                {
                    path: "/finanPedidoRecebimento",
                    name: "finanPedidoRecebimento",
                    component: () =>
                        import("@/view/components/financeiro/pedido-recebimento/listaFinanPedidoRecebimento.vue")
                },
                {
                    path: "/createFinanPedidoRecebimento",
                    name: "createFinanPedidoRecebimento",
                    component: () =>
                        import("@/view/components/financeiro/pedido-recebimento/createFinanPedidoRecebimento.vue")
                },
                {
                    path: "/showFinanPedidoRecebimento/:pedido_id",
                    name: "showFinanPedidoRecebimento",
                    component: () => import("@/view/components/financeiro/pedido-recebimento/showFinanPedidoRecebimento")
                },

                {
                    path: "/finanContasReceber/:pedido_id",
                    name: "finanContasReceber",
                    component: () => import("@/view/components/financeiro/listaFinanContasReceber.vue")
                },

                //#endregion
                //#region procolocos
                {
                    path: "/tipoProtocolo",
                    name: "tipoProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/listaTipoProtocolo.vue")
                },

                {
                    path: "/createTipoProtocolo",
                    name: "createTipoProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createTipoProtocolo.vue")
                },


                {
                    path: "/protocolo",
                    name: "protocolo",
                    component: () =>
                        import("@/view/components/protocolo/listaProtocolo.vue")
                },

                {
                    path: "/createProtocolo",
                    name: "createProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createProtocolo.vue")
                },
                {
                    path: "/tranferenciaProtocolo/:protocolo_id",
                    name: "tranferenciaProtocolo",
                    component: () =>
                        import("@/view/components/protocolo/createTfProtocolo.vue")
                },
                {
                    path: "/tfpArquivo/:tf_protocolo_id/:protocolo_id",
                    name: "tfpArquivo",
                    component: () =>
                        import("@/view/components/protocolo/createArquivoProtocolo.vue")
                },

                //#endregion

                {
                    path: "/servicos",
                    name: "servicos",
                    component: () =>
                        import("@/view/components/financeiro/servicos/listaServicos.vue")
                },

                {
                    path: "/createServico",
                    name: "createServico",
                    component: () =>
                        import("@/view/components/financeiro/servicos/createServicos.vue")
                },




                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                /* ------------------------------ protocolo ----------------------------- */






            ],




        },

        {
            path: "/custom-error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/login_pages/Login-1"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/login_pages/Login-1")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/login_pages/Login-1")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        }
        // {
        //     path: "/comprovante",
        //     name: "comprovante",
        //     component: () =>
        //         import ("@/view/components/financeiro/comprovante.vue")
        // },

    ]
});