
import ApiService from "@/core/services/api.service";


export const SET_LISTA_SERVICOS = "setListServicosRev";
export const SET_LISTA_SERVICOS_REV = "setListServicossRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_CALC_SERVICOS = 'setCalcAliquota'
export const SET_SERVICOS = "setConta"


const actions = {
    //protocolos
    async create_servico(context, value) {

        await ApiService.post('servico', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_servico(context, value) {

        await ApiService.put('servico/'+value.id, value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_servicos(context) {
        await ApiService.get('servico')
            .then(response => {
                context.commit(SET_LISTA_SERVICOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


 

    set_servicos(context, item) {
        context.commit(SET_SERVICOS, item)
    },

    async delete_servicos(context, value) {
        await ApiService.delete("servico/" + value.id)
            .then((response) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "success",
                    message: response.data,
                })
            )
            .catch((error) =>
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: "error",
                    message: error.response.data,
                })
            );
        // context.commit(SET_LISTA_SERVICOS, value)
        context.commit(SET_LISTA_SERVICOS_REV, value)
    },

    set_rota(context, value) {
        context.commit(SET_ROTA, value)
    }



};
const getters = {};

const mutations = {
    [SET_CALC_SERVICOS](state, value) {
        state.calc_servicos = value
    },
    [SET_LISTA_SERVICOS](state, value) {
        state.lista_servicos = value;
    },
    [SET_LISTA_SERVICOS_REV](state, value) {
        state.lista_servicos = state.lista_servicos.filter(
            (servico) => servico.id !== value.id
        );
    },
    [SET_SERVICOS](state, value) {
        state.protocolo = value;
    },
    [SET_ROTA](state, value) {
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

  


};

const state = {
    lista_servicos: [],
    protocolo: {},
    mensagem_alert: "",
    rota: "",
    calc_servicos: {}

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
