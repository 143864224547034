import Vue from "vue";
import App from "./App.vue";
import router from "./core/router/router";

import store from "@/core/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";


// Vue 3rd party plugins
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
import vuetify from "@/core/plugins/vuetify";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSimpleAlert from "vue-simple-alert";
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vuemask";

import VueViewer from 'v-viewer'


// API service init
Vue.use(VueViewer)
Vue.config.productionTip = false;
window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;
Vue.use(VueConfirmDialog);
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.use(VueSimpleAlert);
ApiService.init();


// Remove this to disable mock API
// MockService.init();

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");