
import ApiService from "@/core/services/api.service";


export const SET_LISTA_DOCUMENTO = "setListDocumentoDocRev";
export const SET_LISTA_DOCUMENTO_REV = "setListDocumentoRev";
export const SET_DOCUMENTO = "setListDocumentoRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //documento
    async create_documento(context, value) {

       

        let message = await ApiService.post('documento', value)
        .then(response => ({ tipo: 'success', message: response.data }))
        .catch((error) => ({ tipo: 'error', message: error.response.data }))
      context.commit(SET_MESSAGE_ALERT, message)
    //   context.commit(SET_LISTA_DOCUMENTO_NEW, message.message.conteudo)
    },
    

    async update_documento(context, value) {

        await ApiService.post('documento/update',value)
            .then(response => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit('SET_MESSAGE_ALERT', {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_documentos(context) {
        await ApiService.get('documento')
            .then(response => {
                context.commit(SET_LISTA_DOCUMENTO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async set_arquivo(context,value) {
        await ApiService.get('documento/'+value)
            .then(response => {
                context.commit(SET_DOCUMENTO, response.data)
            })
            .catch((error) => {
                context.commit('SET_MESSAGE_ALERT', {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async delete_documento(context, value) {


        await ApiService.delete("documento/" + value)
          .then((response) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit("SET_MESSAGE_ALERT", {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
        context.commit(SET_LISTA_DOCUMENTO, value)
        context.commit(SET_LISTA_DOCUMENTO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_DOCUMENTO](state, value) {
        state.lista_documentos = value;
    },
    [SET_LISTA_DOCUMENTO_REV](state, value) {
        state.lista_documentos = state.lista_documentos.filter(
            (documentos) => documentos.id !== value.id
        );
    },
   
    [SET_DOCUMENTO](state, value) {
        state.documento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
  
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.message,
        };
      },
    

};

const state = {
    lista_documentos: [],
    lista_documentos_doc: [],
    documento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
